/*
 * Send message
 * Used in another component.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { HttpClient} from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { Http, Headers, RequestOptionsArgs } from '@angular/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: '[angly-sendMessage]',
  templateUrl: './sendMessage.component.html',
  styleUrls: ['./sendMessage.component.scss']
})
export class SendMessageComponent implements OnInit {

   sendMessageForm: FormGroup;
   private sendingMessage: Subscription;
   public doneSending = false;

   constructor(
    private formBuilder: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    private http: Http
    ) {

      this.sendMessageForm = this.formBuilder.group({
         first_name : [null, [Validators.required] ],
         last_name  : [null, [Validators.required] ],
         email      : [null, [Validators.required] ],
    //     subject    : [null, [Validators.required] ],
         textarea   : [null, [Validators.required] ]
      });
   }

   ngOnInit() {
   }

   /*
    * sendMessage
    */
   sendMessage(values: any) {
     if (this.sendMessageForm.valid) {
       console.log(values);
       this.spinnerService.show();
     this.sendingMessage = this.emailService(values)
      .subscribe( () => {
        this.doneSending = true;
        this.spinnerService.hide();
      });
     } else {
       console.log('Error!');
     }
   }

    public emailService(values): Observable<any> {

    const headers = new Headers();
    headers.append('Authorization', 'Token ED72D1DA-9695-4413-B230-EFBBB158C8E8');

      // Create request options
      const requestOpts: RequestOptionsArgs = {};
      requestOpts.headers = headers;

    // Baruch-Server
    // const url = 'http://10.0.0.104:7227/api/mail';

    // LocalHost
    // const url = 'http://localhost:51803/api/mail';

   // Online
   const url = 'https://www.baruchstudio.co.za/mail/api/mail';

    return this.http.post(url, values, requestOpts);
   }


}
