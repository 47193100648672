import { Injectable } from '@angular/core';

/*
 * Menu interface
 */
export interface Menu {
  state: string;
  name?: string;
  type?: string;
  icon?: string;
  children?: ChildrenItems[];
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

const HEADERMENUITEMS = [
  {
    state: 'home',
    name: 'Home',
    type: 'link'
  },
  {
    state: 'about',
    name: 'About',
    type: 'link'
  },
  {
    state: 'contact',
    name: 'Contact Us',
    type: 'link'
  },

];

const FOOTERMENU = [
  {
    state: 'home',
    name: 'Home',
    type: 'link'
  },
  {
    state: 'contact',
    name: 'Contact',
    type: 'link'
  },
  {
    state: 'about',
    name: 'About',
    type: 'link'
  }

];

const EXPLOREMENU = [
  {
    state: 'home',
    name: 'Home',
    type: 'link'
  },
  {
    state: 'contact',
    name: 'Contact',
    type: 'link'
  },
  {
    state: 'about',
    name: 'About',
    type: 'link'
  }

];

const FOOTERMENU2 = [
  {
    state: 'home',
    name: 'Home',
    type: 'link'
  },
  {
    state: 'contact',
    name: 'Contact',
    type: 'link'
  },
  {
    state: 'about',
    name: 'About',
    type: 'link'
  }

];

@Injectable()
export class MenuItems {

  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {
    return HEADERMENUITEMS;
  }

  /*
   * Get footer menu
   */
  getFooterMenu(): Menu[] {
    return FOOTERMENU;
  }

  /*
   * Get the explore menu
   */
  getExploreMenu(): Menu[] {
    return EXPLOREMENU;
  }

  /*
   * Get the footer2
   */
  getFooter2(): Menu[] {
    return FOOTERMENU2;
  }

}
